<template>
  <el-dialog :visible.sync="isShow" :destroy-on-close="false" :modal-append-to-body="false">
    <i class="el-icon el-icon-circle-close" @click="isShow = false"></i>

    <div class="rotaryProgressBar-content">

      <!-- 顶部banner加提示 -->
      <img src="@/assets/images/turntable-head.png" class="turntable-head">
      <div class="tips">{{ $t("完成任务以确认您的票") }}</div>

      <!-- 进度条 -->
      <el-progress class="top-progress" :percentage="allProgress" :text-inside="true" :stroke-width="20"
        define-back-color="#373737" text-color="#FFFFD6"></el-progress>

      <!-- 每项 -->
      <div class="card-item" v-for="item, index in task_rate" :key="index">
        <div class="card-item-title-box">
          <div class="item-title">{{$langKey($t(typeData[item.type].title),{ value: item.condition, currency: $helper.get("rule").currency.code}) }}</div>
          <!-- <el-button class="button_red item-btn" v-if="typeData[item.type].link" round @click="toInfo(typeData[item.type].link)">{{ $t("详情") }}</el-button> -->
        </div>
        <el-progress class="item-progress" :percentage="progress(item.condition,item.complete)" :text-inside="true" :stroke-width="18"
          define-back-color="#1B2535" text-color="#FFFFD6"></el-progress>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      typeData: {
        0: { link: "/all-game" , title:"单笔最低下注 {value} {currency}" },
        1: { link: "/wallet" , title:"单笔最低充值 {value} {currency}" },
        2: { link: "/all-game" , title: "获得一次{value}倍奖励" },
        3: { link: "/wallet" , title: "累计充值 {value} {currency}" },
        4: { link: "" ,title : "在线时长 {value}" },
        5: { link: "/all-game", title:"累计下注 {value} {currency}" },
        6: { link: "" ,title:"游戏时长{value}"},
        7: { link: "/team-center" ,title:"邀请{value}位好友"},
        8: { link: "" ,title:"使用APP登录{value}次"},
        9: { link: "" ,title:"完善银行卡信息"},
      }
    }
  },
  computed:{
    allProgress(){
      if(this.task_rate?.length){
        let progressAll = 0
        this.task_rate.forEach(item=>{
          progressAll += this.progress(item.condition,item.complete);
        })
        return Math.floor(progressAll/this.task_rate.length)
      }
      return 0;
    }
  },
  watch:{
    isShow:{
      handler(v){
        this.$emit("changeShow",v);
      },
      immediate:true
    }
  },
  props:{
    task_rate:{
      default:()=>[]
    }
  },
  methods: {
    show(){
      this.isShow = true
    },
    progress(condition, complete) {
      const progress = Math.floor((complete / condition) * 100)
      return (progress > 100 ? 100 : progress) || 0
    },
    toInfo(url){
      if(url==="/wallet"){
        this.showWallet()
      }else{
        this.$router.push(url)
      }
      this.$emit("toInfo");
      this.isShow = false
    },
    showWallet(){
			if(this.$store.state.loading.wallet) return
      this.$store.state.walletGetData++
		},
  }
}
</script>

<style scoped lang="less" src="@/assets/css/rotaryProgressBar.less"></style>